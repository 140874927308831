// jshint esversion:6

function buildFormData(formData, data, parentKey) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;
    formData.append(parentKey, value);
  }
}

export const NativeEvaluateHandler = {
  // Data: { uuid: 'uuid', name: 'name', model: 'model', fcm_token: 'fcmToken' }
  saveDevice: (data) => {
    const formData = new FormData();
    buildFormData(formData, data);
    
    Rails.ajax({
      type: 'POST',
      url: '/api/user_devices',
      dataType: 'json',
      data: formData,
      success: (data) => {
        console.log("success data:", data)
      },
      error: (error) => {
        console.warn("error data:", error)
      }
    });
  },
  emailOrderControl: () => {
    Rails.ajax({
      type: 'GET',
      url: '/restaurant/services_email_orders/email_orders_list',
      dataType: 'script'
    });
  }
};

export default NativeEvaluateHandler;
